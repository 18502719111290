type CacheIdentifier = string
type CacheValue = unknown

export class CacheService<T extends CacheValue = CacheValue> {
  private cache: Map<CacheIdentifier, T> = new Map()

  public get<T> (identifier: CacheIdentifier): T | undefined {
    return this.cache.get(identifier) as T
  }

  public set (identifier: CacheIdentifier, value: T): void {
    this.cache.set(identifier, value)
  }

  public flush (): void {
    this.cache.clear()
  }

  public has (identifier: CacheIdentifier): boolean {
    return this.cache.has(identifier)
  }
}
