import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { FileReference } from '@/models/file-reference'
import { SelectBoxItemValue } from '@/interfaces/select-box-item'
import { PreviewImage } from '@/models/preview-image'
import { WrinkleType } from '@/models/wrinkle-type'
import { WrinkleTextileRatio } from '@/models/wrinkle-textile-ratio'
import { WrinkleColor } from '@/models/wrinkle-color'

@Exclude()
export class Wrinkle extends Entity implements SelectBoxItemValue {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly previewImage!: FileReference

  @Expose({ toClassOnly: true })
  @Type(() => PreviewImage)
  public readonly previewImages!: PreviewImage[]

  @Expose({ toClassOnly: true })
  public readonly numberOfFolds!: number

  @Expose({ toClassOnly: true })
  public readonly patternRepetitionDistanceInMillimeters!: number

  @Expose({ toClassOnly: true })
  public readonly widthInMillimeters!: number

  @Expose({ toClassOnly: true })
  @Type(() => WrinkleType)
  public readonly type!: WrinkleType

  @Expose({ toClassOnly: true })
  @Type(() => WrinkleColor)
  public readonly color!: WrinkleColor

  @Expose({ toClassOnly: true })
  @Type(() => WrinkleTextileRatio)
  public readonly textileRatio!: WrinkleTextileRatio

  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly zoomImage!: FileReference

  @Expose({ toClassOnly: true })
  public readonly sorting!: number

  public get uniqueIdentifier (): string {
    return this.id || `${this.constructor.name}/${this.uid}`
  }
}
