import { Constructor } from '@/interfaces/constructor'
import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer'

export class NormalizerService {
  public normalize (object: unknown, group: string | null = null): Record<string, unknown> {
    return instanceToPlain(object, { groups: group ? [group] : undefined })
  }

  public denormalize<T> (plainObject: Record<string, unknown>, Model: Constructor, group: string | null = null): T {
    return plainToInstance<T, Record<string, unknown>>(Model as ClassConstructor<T>, plainObject as Record<string, unknown>, { groups: group ? [group] : undefined })
  }
}
