import { BaseComponent } from '@/components/base-component'
import { prop } from 'vue-class-component'
import { PropType } from 'vue'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'

export const baseStepComponentProps = {
  configuratorSnapshot: prop({
    type: Object as PropType<ConfiguratorSnapshot>,
    required: true,
    validator: (value: unknown): boolean => value instanceof ConfiguratorSnapshot
  })
}

export abstract class BaseStepComponent extends BaseComponent {

}
