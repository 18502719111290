import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a83e547"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "slider-image-selector" }
const _hoisted_2 = { class: "slider-image-selector__inner" }
const _hoisted_3 = {
  key: 2,
  class: "slider-image-selector__item__no-image-info"
}
const _hoisted_4 = { class: "slider-image-selector__item__title" }
const _hoisted_5 = {
  key: 1,
  class: "slider-image-selector__controls"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.itemsCollection)
        ? (_openBlock(), _createBlock(_component_swiper, {
            key: 0,
            "slides-per-view": _ctx.defaultSlidesPerView,
            "space-between": _ctx.defaultSpaceBetweenInPx,
            breakpoints: _ctx.breakpointsConfig,
            onSwiper: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setSliderHandler($event)))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsCollection, (item) => {
                return (_openBlock(), _createBlock(_component_swiper_slide, {
                  key: item,
                  class: _normalizeClass(["slider-image-selector__item", { 'slider-image-selector__item--current': _ctx.isSelectedItem(item) }]),
                  onClick: ($event: any) => (_ctx.selectionChanged(item))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["slider-image-selector__item__image-wrapper", {
                  'slider-image-selector__item__image-wrapper--no-image': !item.imageUrl,
                  'slider-image-selector__item__image-wrapper--has-hover-image': item.hoverImageUrl
               }])
                    }, [
                      (item.imageUrl)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "slider-image-selector__item__image slider-image-selector__item__image--default",
                            style: _normalizeStyle({ backgroundImage: item.imageUrl ? `url(${item.imageUrl})` : 'none' })
                          }, null, 4))
                        : _createCommentVNode("", true),
                      (item.hoverImageUrl)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: "slider-image-selector__item__image slider-image-selector__item__image--hover",
                            style: _normalizeStyle({ backgroundImage: item.hoverImageUrl ? `url(${item.hoverImageUrl})` : 'none' })
                          }, null, 4))
                        : _createCommentVNode("", true),
                      (!item.imageUrl)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('missingPreviewImage')), 1))
                        : _createCommentVNode("", true)
                    ], 2),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["class", "onClick"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["slides-per-view", "space-between", "breakpoints"]))
        : _createCommentVNode("", true),
      (_ctx.navigationVisibilityState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "slider-image-selector__controls__btn slider-image-selector__controls__btn--prev",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToPrev()))
            }),
            _createElementVNode("button", {
              class: "slider-image-selector__controls__btn slider-image-selector__controls__btn--next",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToNext()))
            })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}