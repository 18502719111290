
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import SideSelector from '@/components/Form/Input/SideSelector.vue'
import NumberInput from '@/components/Form/Input/NumberInput.vue'

@Options({
  components: {
    NumberInput,
    SideSelector
  },
  props: baseStepComponentProps
})
export default class SizeConfiguration extends BaseStepComponent {
}
