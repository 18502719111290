import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ConfiguratorView from '@/views/ConfiguratorView.vue'
import { createInstance } from '@/app.container'
import { ConfiguratorStepService } from '@/services/configurator-step.service'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:step?',
    name: 'configurator',
    component: ConfiguratorView,
    props: (route) => ({
      step: route.params.step || createInstance<ConfiguratorStepService>(ConfiguratorStepService).firstStep
    })
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
