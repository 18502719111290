import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { PreviewImage } from '@/models/preview-image'
import { FileReference } from '@/models/file-reference'
import { CarouselImageItemValue } from '@/interfaces/carousel-image-item'

@Exclude()
export class Room extends Entity implements CarouselImageItemValue {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  @Type(() => PreviewImage)
  public readonly defaultPreviewImage!: PreviewImage

  @Expose({ toClassOnly: true })
  @Type(() => PreviewImage)
  public readonly previewImages!: PreviewImage[]

  public get uniqueIdentifier (): string {
    return this.id || `${this.constructor.name}/${this.uid}`
  }

  public get previewImage (): FileReference | null {
    return this.defaultPreviewImage?.image || null
  }

  public get previewImageMdUrl (): string | null {
    return this.defaultPreviewImage?.imageMdUrl || null
  }

  public get previewImageSmUrl (): string | null {
    return this.defaultPreviewImage?.imageSmUrl || null
  }
}
