import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c38e8fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step-paginator" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.previousStep)
      ? (_openBlock(), _createBlock(_component_SecondaryButton, {
          key: 0,
          "link-to-route": { name: 'configurator', params: { step: _ctx.previousStep }},
          disabled: !_ctx.canGoToPreviousStep,
          class: "step-paginator__btn-prev"
        }, null, 8, ["link-to-route", "disabled"]))
      : _createCommentVNode("", true),
    (_ctx.nextStep)
      ? (_openBlock(), _createBlock(_component_PrimaryButton, {
          key: 1,
          "link-to-route": { name: 'configurator', params: { step: _ctx.nextStep }},
          inactive: !_ctx.canGoToNextStep
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('nextStep')), 1)
          ]),
          _: 1
        }, 8, ["link-to-route", "inactive"]))
      : (_openBlock(), _createBlock(_component_PrimaryButton, {
          key: 2,
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit(_ctx.emitKeys.SaveClick)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('finish')), 1)
          ]),
          _: 1
        }))
  ]))
}