import { Exclude, Expose } from 'class-transformer'
import { HydraErrorResponse } from '@/models/hydra/hydra-error-response'

export type HydraValidationError = {
  code: number,
  propertyPath: string,
  message: string
}

@Exclude()
export class HydraErrorValidationResponse extends HydraErrorResponse {
  @Expose({
    name: 'violations',
    toClassOnly: true
  })
  public violations!: HydraValidationError[]
}
