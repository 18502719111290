import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { PreviewImage } from '@/models/preview-image'
import { Transparency } from '@/models/transparency'
import { Pattern } from '@/models/pattern'
import { Color } from '@/models/color'
import { TextileFeature } from '@/models/textile-feature'
import { FileReference } from '@/models/file-reference'

@Exclude()
export class Curtain extends Entity {
  @Expose({ toClassOnly: true })
  @Type(() => PreviewImage)
  public readonly previewImages!: PreviewImage[]

  @Expose({ toClassOnly: true })
  @Type(() => Transparency)
  public readonly transparency!: Transparency

  @Expose({ toClassOnly: true })
  @Type(() => Pattern)
  public readonly pattern!: Pattern

  @Expose({ toClassOnly: true })
  @Type(() => Color)
  public readonly color!: Color

  @Expose({ toClassOnly: true })
  @Type(() => TextileFeature)
  public readonly textileFeatures!: TextileFeature[]

  public get uniqueIdentifier (): string {
    return this.id || `${this.constructor.name}/${this.uid}`
  }

  public hasAnyOfTextileFeatures (textileFeatures: TextileFeature[]): boolean {
    const textileFeaturesUids = textileFeatures.map(textileFeature => textileFeature.uid)

    return this.textileFeatures.some(textileFeature => textileFeaturesUids.includes(textileFeature.uid))
  }

  public get previewImage (): FileReference | null {
    if (!this.previewImages || !this.previewImages[0]) {
      return null
    }

    return this.previewImages[0].image || null
  }
}
