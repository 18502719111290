export class UrlService {
  private static _parsedApiBaseUrl: URL

  public static get apiBaseUrl (): string {
    const partedHostname = window.location.hostname.split('.')
    partedHostname[0] += '-api'

    return `https://${partedHostname.join('.')}/_api`
  }

  private static get parsedApiBaseUrl (): URL {
    return UrlService._parsedApiBaseUrl || (UrlService._parsedApiBaseUrl = new URL(UrlService.apiBaseUrl, window.location.href))
  }

  public static removeApiBaseUrlPathFromUrl (url: string): string {
    if (UrlService.isAbsoluteUrl(url)) {
      const parsedUrl = new URL(url)
      if (parsedUrl.pathname.startsWith(this.parsedApiBaseUrl.pathname)) {
        parsedUrl.pathname = parsedUrl.pathname.slice(this.parsedApiBaseUrl.pathname.length)
      }

      return parsedUrl.toString()
    }

    if (url.startsWith(this.parsedApiBaseUrl.pathname)) {
      return url.slice(this.parsedApiBaseUrl.pathname.length)
    }

    return url
  }

  private static isAbsoluteUrl (url: string): boolean {
    return url.startsWith('//') || url.indexOf('://') < url.indexOf('.')
  }
}
