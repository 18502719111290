import { EntityService } from '@/services/entity.service'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'

export class ConfiguratorSnapshotService extends EntityService<ConfiguratorSnapshot> {
  protected model = ConfiguratorSnapshot
  protected defaultCollectionUrl = 'curtains-configurator/configurator-snapshots'

  public create (configuratorSnapshot: ConfiguratorSnapshot): Promise<ConfiguratorSnapshot> {
    return this.post<Partial<ConfiguratorSnapshot>>(this.defaultCollectionUrl, this.normalizerService.normalize(configuratorSnapshot, 'snapshot.create'))
      .then(({ data }) => this.normalizerService.denormalize<ConfiguratorSnapshot>(data, this.model, 'snapshot.create'))
  }

  public fetchByCode (code: string): Promise<ConfiguratorSnapshot> {
    return this.get<Partial<ConfiguratorSnapshot>>(`${this.defaultCollectionUrl}/${code}`)
      .then(({ data }) => this.normalizerService.denormalize<ConfiguratorSnapshot>(data, this.model, 'snapshot.load'))
  }
}
