import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CarouselImageSelector = _resolveComponent("CarouselImageSelector")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CarouselImageSelector, {
      items: _ctx.roomsAsCarouselImageItems,
      label: _ctx.$t('roomSelectFieldLabel'),
      modelValue: _ctx.configuratorSnapshot.selectedRoom,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuratorSnapshot.selectedRoom) = $event))
    }, null, 8, ["items", "label", "modelValue"])
  ]))
}