import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4982652c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6" }
const _hoisted_3 = { class: "configurator__main-header" }
const _hoisted_4 = { class: "col-sm-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StepNavigator = _resolveComponent("StepNavigator")!
  const _component_RoomSelection = _resolveComponent("RoomSelection")!
  const _component_SnapshotLoader = _resolveComponent("SnapshotLoader")!
  const _component_CurtainConfiguration = _resolveComponent("CurtainConfiguration")!
  const _component_WrinkleConfiguration = _resolveComponent("WrinkleConfiguration")!
  const _component_SizeConfiguration = _resolveComponent("SizeConfiguration")!
  const _component_SnapshotCode = _resolveComponent("SnapshotCode")!
  const _component_StepPaginator = _resolveComponent("StepPaginator")!
  const _component_CurtainPreview = _resolveComponent("CurtainPreview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('header')), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('introMessage')), 1),
      _createVNode(_component_StepNavigator, {
        "current-step": _ctx.step,
        "configurator-snapshot": _ctx.configuratorSnapshot
      }, null, 8, ["current-step", "configurator-snapshot"]),
      (_ctx.step === _ctx.steps.Room && _ctx.canGoToStep(_ctx.steps.Room))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_RoomSelection, { "configurator-snapshot": _ctx.configuratorSnapshot }, null, 8, ["configurator-snapshot"]),
            _createVNode(_component_SnapshotLoader, {
              onSnapshotLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadSnapshot($event))),
              class: "configurator__snapshot-loader"
            })
          ], 64))
        : _createCommentVNode("", true),
      (_ctx.step === _ctx.steps.Textile && _ctx.canGoToStep(_ctx.steps.Textile))
        ? (_openBlock(), _createBlock(_component_CurtainConfiguration, {
            key: 1,
            "configurator-snapshot": _ctx.configuratorSnapshot
          }, null, 8, ["configurator-snapshot"]))
        : _createCommentVNode("", true),
      (_ctx.step === _ctx.steps.Wrinkle && _ctx.canGoToStep(_ctx.steps.Wrinkle))
        ? (_openBlock(), _createBlock(_component_WrinkleConfiguration, {
            key: 2,
            "configurator-snapshot": _ctx.configuratorSnapshot
          }, null, 8, ["configurator-snapshot"]))
        : _createCommentVNode("", true),
      (_ctx.step === _ctx.steps.Size && _ctx.canGoToStep(_ctx.steps.Size))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
            _createVNode(_component_SizeConfiguration, { "configurator-snapshot": _ctx.configuratorSnapshot }, null, 8, ["configurator-snapshot"]),
            _createVNode(_component_SnapshotCode, {
              "configurator-snapshot": _ctx.configuratorSnapshot,
              class: "configurator__snapshot-code"
            }, null, 8, ["configurator-snapshot"])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_StepPaginator, {
        "current-step": _ctx.step,
        "configurator-snapshot": _ctx.configuratorSnapshot,
        onSaveClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.saveConfiguratorSnapshot()))
      }, null, 8, ["current-step", "configurator-snapshot"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CurtainPreview, { "configurator-snapshot": _ctx.configuratorSnapshot }, null, 8, ["configurator-snapshot"])
    ])
  ]))
}