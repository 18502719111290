import { BaseComponent } from '@/components/base-component'
import { prop } from 'vue-class-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { ConfiguratorStep } from '@/enums/configurator-step'

export const baseNavigationComponentProps = {
  configuratorSnapshot: prop({
    type: ConfiguratorSnapshot,
    required: true,
    validator: (value: unknown): boolean => value instanceof ConfiguratorSnapshot
  }),
  currentStep: prop({
    type: String,
    required: true,
    validator: (value: unknown): boolean => Object.keys(ConfiguratorStep).some(key => (ConfiguratorStep as Record<string, string>)[key] === value)
  })
}

export abstract class BaseNavigationComponent extends BaseComponent {

}
