import { Entity } from '@/models/entity'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { AppConfigApiResponse } from '@/interfaces/api/response/app-config-api-response'
import { Language } from '@/models/language'
import { FileReference } from '@/models/file-reference'

@Exclude()
export class AppConfig extends Entity {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  @Transform(
    ({ value: labels }: { value: AppConfigApiResponse['labels'] }) => new Map(labels.map(label => [String(label.slug), String(label.value)])),
    { toClassOnly: true }
  )
  public readonly labels!: Map<string, string>

  @Expose({ toClassOnly: true })
  @Transform(
    ({ value: vars }: { value: AppConfigApiResponse['styleVariables'] }) => new Map(vars.map(variable => [String(variable.slug), String(variable.value)])),
    { toClassOnly: true }
  )
  public readonly styleVariables!: Map<string, string>

  @Expose({ toClassOnly: true })
  @Type(() => Language)
  public readonly supportedLanguages!: Language[]

  @Expose({ toClassOnly: true })
  @Type(() => Language)
  public readonly language!: Language

  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly companyLogo!: FileReference

  @Expose({ toClassOnly: true })
  public readonly companyWebsiteUrl!: string

  @Expose({ toClassOnly: true })
  public readonly snapshotCodeLength: number = 8
}
