import { HydraView } from '@/models/hydra/hydra-view'
import { Exclude, Expose, Type } from 'class-transformer'

@Exclude()
export class HydraCollectionResponse<T> {
  @Expose({ name: 'hydra:member' })
  public member: T[] = []

  @Expose({ name: 'hydra:totalItems' })
  public totalItems!: number

  @Expose({ name: 'hydra:view' })
  @Type(() => HydraView)
  public view: HydraView = new HydraView()
}
