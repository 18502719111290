import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03ad56bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app__container container" }
const _hoisted_2 = { class: "app__header" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 2,
  class: "app__language-selector"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_notifications = _resolveComponent("notifications")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.$ccAppConfig)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_2, [
              (_ctx.$ccAppConfig.companyWebsiteUrl)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.$ccAppConfig.companyWebsiteUrl,
                    target: "_blank"
                  }, [
                    (_ctx.$ccAppConfig.companyLogo)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.$ccAppConfig.companyLogo.url,
                          alt: _ctx.$ccAppConfig.title,
                          class: "app__header__logo"
                        }, null, 8, _hoisted_4))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_3))
                : (_ctx.$ccAppConfig.companyLogo?.url)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 1,
                      src: _ctx.$ccAppConfig.companyLogo.url,
                      alt: _ctx.$ccAppConfig.title,
                      class: "app__header__logo"
                    }, null, 8, _hoisted_5))
                  : _createCommentVNode("", true),
              (_ctx.availableLanguages.length > 1)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableLanguages, (language) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: language.code,
                        class: "app__language-selector__element"
                      }, [
                        _createVNode(_component_LinkButton, {
                          onButtonClick: ($event: any) => (_ctx.changeLanguage(language)),
                          class: _normalizeClass(["app__language-selector__item", { 'app__language-selector__item--current': _ctx.currentLanguage.code === language.code }])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(language.code), 1)
                          ]),
                          _: 2
                        }, 1032, ["onButtonClick", "class"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_router_view)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.$t('error.couldNotInitializeAppDueMissingConfig')), 1)
          ], 64))
    ]),
    _createVNode(_component_notifications, {
      position: "top center",
      class: "app__notification"
    })
  ], 64))
}