import { Exclude, Expose, Transform } from 'class-transformer'

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

class OrderCollection {
  protected _properties: Map<string, OrderDirection> = new Map()

  public get isEmpty () {
    return !this._properties.size
  }

  public get propertiesAsJson (): Record<string, unknown> {
    const object: Record<string, unknown> = {}

    Array.from(this._properties.entries()).forEach(([property, direction]) => {
      object[property] = direction
    })

    return object
  }

  public add (property: string, direction: string | OrderDirection = OrderDirection.ASC) {
    this._properties.set(property, direction.toString().toLowerCase() === OrderDirection.DESC ? OrderDirection.DESC : OrderDirection.ASC)
  }

  public remove (property: string) {
    this._properties.delete(property)
  }

  public toggle (property: string) {
    if (this._properties.has(property)) {
      if (this._properties.get(property) === OrderDirection.DESC) {
        this.remove(property)
      } else {
        this._properties.set(property, OrderDirection.DESC)
      }
    } else {
      this.add(property, OrderDirection.ASC)
    }
  }

  public isSortingBy (property: string, direction?: OrderDirection) {
    if (!this._properties.has(property)) {
      return false
    }

    if (typeof direction === 'undefined') {
      return true
    }

    return (this._properties.get(property) as OrderDirection).toString().toLowerCase() === direction.toString().toLowerCase()
  }
}

@Exclude()
export class Filter {
  @Expose({ toPlainOnly: true })
  public itemsPerPage = 10

  public pageIndex = 0

  @Expose({ toPlainOnly: true })
  @Transform(({ value: order }: { value: OrderCollection }) => order.propertiesAsJson, { toPlainOnly: true })
  public order: OrderCollection = new OrderCollection()

  public constructor (json?: Partial<Filter>) {
    Object.assign(this, json)
  }

  public resetPage (): Filter {
    this.pageIndex = 0

    return this
  }
}
