import { HydraErrorResponse } from '@/models/hydra/hydra-error-response'

export class HttpGenericError extends Error implements Error {
  public name!: string
  public message!: string
  public code!: number
  public response: unknown
  public hydraErrorResponse!: HydraErrorResponse

  public static fromHydraErrorResponse (response: HydraErrorResponse): HttpGenericError {
    const error = new this()
    error.message = response.description
    error.name = response.title
    error.code = response.code
    error.hydraErrorResponse = response

    return error
  }
}
