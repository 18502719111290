import { Entity } from '@/models/entity'
import { Exclude, Expose } from 'class-transformer'
import { SelectBoxItemValue } from '@/interfaces/select-box-item'

@Exclude()
export class TextileFeature extends Entity implements SelectBoxItemValue {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  public readonly sorting!: number

  public get uniqueIdentifier (): string {
    return this.id || `${this.constructor.name}/${this.uid}`
  }
}
