import 'reflect-metadata'
import { container } from 'inversify-props'
import { NormalizerService } from '@/services/normalizer.service'
import { AppConfigService } from '@/services/app-config.service'
import { RoomService } from '@/services/room.service'
import { ConfiguratorStepService } from '@/services/configurator-step.service'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import { ConfiguratorSnapshotService } from '@/services/configurator-snapshot.service'
import { StylesService } from '@/services/styles.service'
import { LanguageService } from '@/services/language.service'
import { CacheService } from '@/services/cache.service'
import { SharedPromiseService } from '@/services/shared-promise.service'
import { PrefetchingService } from '@/services/prefetching.service'
import { Constructor } from '@/interfaces/constructor'

/**
 * {@link https://medium.com/@fernalvarez/dependency-injection-made-easy-for-vuejs-and-vuex-with-typescript-3bc738c17e05}
 */
container.addTransient<NormalizerService>(NormalizerService)
container.addTransient<AppConfigService>(AppConfigService)
container.addTransient<RoomService>(RoomService)
container.addTransient<ConfiguratorStepService>(ConfiguratorStepService)
container.addTransient<AvailablePreviewImageService>(AvailablePreviewImageService)
container.addTransient<ConfiguratorSnapshotService>(ConfiguratorSnapshotService)
container.addTransient<StylesService>(StylesService)
container.addSingleton<LanguageService>(LanguageService)
container.addSingleton<CacheService>(CacheService)
container.addSingleton<SharedPromiseService>(SharedPromiseService)
container.addSingleton<PrefetchingService>(PrefetchingService)

export function createInstance<T> (constructor: Constructor): T {
  const serviceIdentifier = new Date().getTime().toString(36).concat(performance.now().toString(), Math.random().toString()).replace(/\./g, '')
  container.addTransient<T>(constructor, serviceIdentifier)
  const instance = container.get<T>(serviceIdentifier)
  container.unbind(serviceIdentifier)

  return instance
}

export default container
