import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { PreviewImage } from '@/models/preview-image'
import { Inject } from 'inversify-props'
import { RoomService } from '@/services/room.service'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'

export class AvailablePreviewImageService {
  @Inject() private roomService!: RoomService

  public fetchByConfigurationSnapshot (
    configurationSnapshot: ConfiguratorSnapshot,
    activatedFilters: AvailablePreviewImageFilter = AvailablePreviewImageFilter.All
  ): Promise<PreviewImage[]> {
    const selectedRoom = configurationSnapshot.selectedRoom

    if (!selectedRoom) {
      throw new Error('Can not fetch available curtains without selected room #1662049291957')
    }

    return this.roomService.fetchCurtainsToRoomByRoom(selectedRoom).then(previewImages => {
      return previewImages.filter(previewImage => {
        return this.isPreviewImageMatchingTextileFeaturesFilters(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedTransparency(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedPattern(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkle(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkleType(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkleNumberOfFolds(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkleColor(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkleTextileRatio(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinkleWidth(previewImage, activatedFilters, configurationSnapshot) &&
          this.isPreviewImageMatchingSelectedWrinklePatternRepetitionDistance(previewImage, activatedFilters, configurationSnapshot)
      })
    })
  }

  private isPreviewImageMatchingTextileFeaturesFilters (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return configurationSnapshot.selectedTextileFeatures.length === 0 ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.TextileFeatures) ||
      previewImage.curtain.hasAnyOfTextileFeatures(configurationSnapshot.selectedTextileFeatures)
  }

  private isPreviewImageMatchingSelectedTransparency (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedTransparency ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.Transparency) ||
      previewImage.curtain.transparency?.id === configurationSnapshot.selectedTransparency.id
  }

  private isPreviewImageMatchingSelectedPattern (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedPattern ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.Pattern) ||
      previewImage.curtain.pattern?.id === configurationSnapshot.selectedPattern.id
  }

  private isPreviewImageMatchingSelectedWrinkle (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkle ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.Wrinkle) ||
      previewImage.wrinkle?.id === configurationSnapshot.selectedWrinkle.id
  }

  private isPreviewImageMatchingSelectedWrinkleType (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkleType ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinkleType) ||
      previewImage.wrinkle.type?.id === configurationSnapshot.selectedWrinkleType.id
  }

  private isPreviewImageMatchingSelectedWrinkleNumberOfFolds (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkleNumberOfFolds ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinkleNumberOfFolds) ||
      previewImage.wrinkle.numberOfFolds === configurationSnapshot.selectedWrinkleNumberOfFolds.value
  }

  private isPreviewImageMatchingSelectedWrinkleColor (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkleColor ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinkleColor) ||
      previewImage.wrinkle.color?.uid === configurationSnapshot.selectedWrinkleColor.uid
  }

  private isPreviewImageMatchingSelectedWrinkleTextileRatio (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkleTextileRatio ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinkleTextileRatio) ||
      previewImage.wrinkle.textileRatio?.uid === configurationSnapshot.selectedWrinkleTextileRatio.uid
  }

  private isPreviewImageMatchingSelectedWrinkleWidth (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinkleWidthInMillimeters ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinkleWidth) ||
      previewImage.wrinkle.widthInMillimeters === configurationSnapshot.selectedWrinkleWidthInMillimeters.value
  }

  private isPreviewImageMatchingSelectedWrinklePatternRepetitionDistance (
    previewImage: PreviewImage,
    activatedFilters: AvailablePreviewImageFilter,
    configurationSnapshot: ConfiguratorSnapshot
  ): boolean {
    return !configurationSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters ||
      !this.isFilterActivated(activatedFilters, AvailablePreviewImageFilter.WrinklePatternRepetitionDistance) ||
      previewImage.wrinkle.patternRepetitionDistanceInMillimeters === configurationSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters.value
  }

  private isFilterActivated (activeFilters: AvailablePreviewImageFilter, filterToCheck: AvailablePreviewImageFilter): boolean {
    return (activeFilters & filterToCheck) === filterToCheck
  }
}
