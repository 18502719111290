
import { BaseComponent } from '@/components/base-component'
import Modal from '@/components/Ui/Modal.vue'
import { Options } from 'vue-class-component'
import PrimaryButton from '@/components/Ui/Button/PrimaryButton.vue'
import { Inject } from 'inversify-props'
import { ConfiguratorSnapshotService } from '@/services/configurator-snapshot.service'
import LinkButton from '@/components/Ui/Button/LinkButton.vue'
import TextInput from '@/components/Form/Input/TextInput.vue'

enum EmitKey {
  SnapshotLoaded = 'snapshot-loaded'
}

@Options({
  components: {
    TextInput,
    LinkButton,
    PrimaryButton,
    Modal
  },
  emits: [EmitKey.SnapshotLoaded],
  watch: {
    showModal: function (newValue: boolean) {
      if (!newValue) {
        this.reset()
        this.resetCode()
      }
    }
  }
})
export default class SnapshotLoader extends BaseComponent {
  @Inject() private configuratorSnapshotService!: ConfiguratorSnapshotService
  public showModal = false
  public configuratorSnapshotCode = ''
  public snapshotLoadingInProgress = false

  public loadSnapshot (): void {
    this.snapshotLoadingInProgress = true
    this.configuratorSnapshotService.fetchByCode(this.configuratorSnapshotCode)
      .then((configuratorSnapshot) => {
        this.$emit(EmitKey.SnapshotLoaded, configuratorSnapshot)
        this.$notifySuccess(this.$t('loadOrder.success'))
        this.showModal = false
        this.resetCode()
      })
      .catch((error) => {
        this.$notifyError(this.$t('loadOrder.error'))
        console.error(error)
      })
      .finally(() => {
        this.snapshotLoadingInProgress = false
      })
  }

  public reset (): void {
    this.resetCode()
    this.snapshotLoadingInProgress = false
  }

  private resetCode (): void {
    this.configuratorSnapshotCode = ''
  }
}
