
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import Label from '@/components/Form/Label.vue'

@Options({
  components: { Label },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    suffix: prop({
      type: String,
      required: false
    }),
    min: prop({
      type: Number,
      default: 0
    }),
    max: prop({
      type: Number,
      default: null
    }),
    step: prop({
      type: Number,
      default: 1
    }),
    modelValue: prop({
      type: Number,
      required: true
    })
  }
})
export default class NumberInput extends BaseComponent {
  private step!: number
  private readonly modelValue!: number

  public get value (): number {
    return this.modelValue
  }

  public set value (value: number) {
    this.$emit('update:modelValue', parseFloat(String(value)))
  }

  public increment (): void {
    this.value = this.value + this.step
  }

  public decrement (): void {
    this.value = this.value - this.step
  }
}
