
import { Options } from 'vue-class-component'
import {
  BaseNavigationComponent,
  baseNavigationComponentProps
} from '@/components/CurtainConfigurator/base-navigation-component'
import { ConfiguratorStep } from '@/enums/configurator-step'
import { Inject } from 'inversify-props'
import { ConfiguratorStepService } from '@/services/configurator-step.service'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'

@Options({
  props: baseNavigationComponentProps,
  watch: {
    currentStep: function () {
      this.scrollIntoView()
    }
  }
})
export default class StepNavigator extends BaseNavigationComponent {
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public readonly steps = ConfiguratorStep
  @Inject() private configuratorStepService!: ConfiguratorStepService

  public get htmlWrapperElement (): HTMLElement {
    if (!this.$refs.wrapperElement) {
      console.warn('Could not determine wrapper element of StepNavigator component #1672594891447')
    }

    return this.$refs.wrapperElement as HTMLElement
  }

  public canGoToStep (step: ConfiguratorStep): boolean {
    return this.configuratorStepService.canGoToStep(this.configuratorSnapshot, step)
  }

  public scrollIntoView (): void {
    setTimeout(() => {
      this.htmlWrapperElement.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      })
    }, 10)
  }
}
