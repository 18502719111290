import { Exclude, Expose, Transform } from 'class-transformer'
import { UrlService } from '@/services/url.service'

@Exclude()
export class HydraView {
  @Expose({ name: 'hydra:first' })
  @Transform(({ value: pageUrl }: { value: string }) => pageUrl && UrlService.removeApiBaseUrlPathFromUrl(pageUrl), { toClassOnly: true })
  public first!: string

  @Expose({ name: 'hydra:last' })
  @Transform(({ value: pageUrl }: { value: string }) => pageUrl && UrlService.removeApiBaseUrlPathFromUrl(pageUrl), { toClassOnly: true })
  public last!: string

  @Expose({ name: 'hydra:next' })
  @Transform(({ value: pageUrl }: { value: string }) => pageUrl && UrlService.removeApiBaseUrlPathFromUrl(pageUrl), { toClassOnly: true })
  public next!: string

  @Expose({ name: 'hydra:prev' })
  @Transform(({ value: pageUrl }: { value: string }) => pageUrl && UrlService.removeApiBaseUrlPathFromUrl(pageUrl), { toClassOnly: true })
  public prev!: string

  @Expose({ name: 'hydra:pages' })
  @Transform(({ value: pages }: { value: string[] }) => pages.map(pageUrl => UrlService.removeApiBaseUrlPathFromUrl(pageUrl)), { toClassOnly: true })
  public pages!: string[]

  @Expose({ name: 'hydra:page' })
  public page!: number

  public get hasNext (): boolean {
    return !!this.next
  }

  public get hasPrev (): boolean {
    return !!this.prev
  }
}
