import { AppConfig } from '@/models/app-config'
import { Language } from '@/models/language'
import { messages } from '@/locales'
import { LocaleMessages, VueMessageType } from 'vue-i18n'

export class LanguageService {
  public appConfig: AppConfig | null = null

  public get currentCode (): string {
    return this.selectedLanguageCode || this.browserLanguageCode
  }

  public get messages (): { [s: string]: LocaleMessages<VueMessageType> } {
    if (this.appConfig) {
      messages[this.appConfig.language.code] = { ...messages[this.appConfig.language.code] || {}, ...Object.fromEntries(this.appConfig.labels) }
    }

    return messages
  }

  public get allAvailable (): Language[] {
    if (!this.appConfig) {
      return []
    }

    return this.appConfig.supportedLanguages
  }

  public selectLanguage (language: Language): void {
    localStorage.setItem('locale', language.code)
  }

  public get default (): Language | null {
    if (!this.appConfig) {
      return null
    }

    return this.appConfig.supportedLanguages[0]
  }

  private get selectedLanguageCode (): string | null {
    return localStorage.getItem('locale')
  }

  private get browserLanguageCode (): string {
    return navigator.language.slice(0, 2).toLowerCase()
  }
}
