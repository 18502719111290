
import { Options } from 'vue-class-component'
import {
  BaseNavigationComponent,
  baseNavigationComponentProps
} from '@/components/CurtainConfigurator/base-navigation-component'
import { ConfiguratorStep } from '@/enums/configurator-step'
import { Inject } from 'inversify-props'
import { ConfiguratorStepService } from '@/services/configurator-step.service'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import PrimaryButton from '@/components/Ui/Button/PrimaryButton.vue'
import SecondaryButton from '@/components/Ui/Button/SecondaryButton.vue'

enum EmitKey {
  SaveClick = 'save-click'
}

@Options({
  components: {
    SecondaryButton,
    PrimaryButton
  },
  props: baseNavigationComponentProps,
  emits: [EmitKey.SaveClick]
})
export default class StepPaginator extends BaseNavigationComponent {
  public readonly emitKeys = EmitKey
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public readonly currentStep!: ConfiguratorStep
  @Inject() private configuratorStepService!: ConfiguratorStepService

  public get nextStep (): ConfiguratorStep | null {
    return this.configuratorStepService.getStepAfterStep(this.currentStep)
  }

  public get previousStep (): ConfiguratorStep | null {
    return this.configuratorStepService.getStepBeforeStep(this.currentStep)
  }

  public get canGoToPreviousStep (): boolean {
    return !!this.previousStep && this.canGoToStep(this.previousStep)
  }

  public get canGoToNextStep (): boolean {
    return !!this.nextStep && this.canGoToStep(this.nextStep)
  }

  private canGoToStep (step: ConfiguratorStep): boolean {
    return this.configuratorStepService.canGoToStep(this.configuratorSnapshot, step)
  }
}
