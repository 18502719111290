import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class Language {
  @Expose({ toClassOnly: true })
  public readonly id!: number

  @Expose({ toClassOnly: true })
  public readonly code!: string
}
