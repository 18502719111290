
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import LinkButton from '@/components/Ui/Button/LinkButton.vue'

@Options({
  components: { LinkButton },
  props: {
    configuratorSnapshot: prop({
      type: ConfiguratorSnapshot,
      required: true,
      validator: (value: unknown): boolean => value instanceof ConfiguratorSnapshot
    })
  }
})
export default class SnapshotCode extends BaseComponent {
  public readonly configuratorSnapshot!: ConfiguratorSnapshot

  public copyCode (): void {
    if (!this.configuratorSnapshot.code) {
      return
    }

    const textarea = document.createElement('textarea')
    textarea.style.position = 'fixed'
    textarea.style.left = '0'
    textarea.style.top = '0'
    textarea.style.opacity = '0'
    textarea.value = this.configuratorSnapshot.code
    document.body.appendChild(textarea)
    textarea.focus()
    textarea.select()
    // https://stackoverflow.com/a/70831583/1588346
    document.execCommand('copy')
    document.body.removeChild(textarea)
    this.$notifyInfo(this.$t('copied'))
  }
}
