import { AxiosRequestConfig } from 'axios'
import { HydraCollectionResponse } from '@/models/hydra/hydra-collection-response'
import { Constructor } from '@/interfaces/constructor'
import { Entity } from '@/models/entity'
import { Filter } from '@/models/filter'
import { ApiService, GetRequestSettings } from '@/services/api.service'

export abstract class EntityService<T extends Entity> extends ApiService {
  public lastCollectionResponse: HydraCollectionResponse<T> | undefined
  protected abstract model: Constructor
  protected useSharedPromises = false
  protected useResponseCache = false
  protected defaultCollectionUrl: string | null = null

  public fetchCollection (params?: Filter): Promise<T[]> {
    if (this.defaultCollectionUrl === null) {
      throw new Error(`${this.constructor.name}.defaultCollectionUrl is not defined thus could not call ${this.constructor.name}.fetchCollection #1622615277722`)
    }

    return this.fetchCollectionRaw(this.defaultCollectionUrl, { params })
  }

  public fetchCollectionRaw (url: string, config: AxiosRequestConfig = {}): Promise<T[]> {
    return this.get<Record<string, unknown>>(url, config, this.getRequestSettings)
      .then(({ data }) => {
        this.lastCollectionResponse = this.normalizerService.denormalize<HydraCollectionResponse<Record<string, unknown>>>(data, HydraCollectionResponse) as HydraCollectionResponse<T>
        this.lastCollectionResponse.member = this.lastCollectionResponse.member.map((json) => this.denormalizeItem(json as Record<string, unknown>))

        return this.lastCollectionResponse.member.slice()
      })
  }

  public fetchCollectionNextPage (): Promise<T[]> {
    if (!this.lastCollectionResponse) {
      throw new Error('Failed on `fetchCollectionNextPage`. Can not fetch next page without previous response #1622535194889')
    }
    if (!this.lastCollectionResponse.view.hasNext) {
      return Promise.resolve([])
    }

    return this.fetchCollectionRaw(this.lastCollectionResponse.view.next)
  }

  public fetchCollectionPrevPage (): Promise<T[]> {
    if (!this.lastCollectionResponse) {
      throw new Error('Failed on `fetchCollectionPrevPage`. Can not fetch previous page without previous response #1622535271357')
    }
    if (!this.lastCollectionResponse.view.hasPrev) {
      return Promise.resolve([])
    }

    return this.fetchCollectionRaw(this.lastCollectionResponse.view.prev)
  }

  public fetchCollectionPage (pageIndex: number): Promise<T[]> {
    if (!this.lastCollectionResponse) {
      throw new Error(`Failed on \`fetchCollectionPage\`. Can not fetch page ${pageIndex} without previous response #1622535313675`)
    }
    if (!this.lastCollectionResponse.view.hasPrev) {
      return Promise.resolve([])
    }

    return this.fetchCollectionRaw(this.lastCollectionResponse.view.pages[pageIndex])
  }

  public fetchItem (uid = 0, path: string = this.defaultCollectionUrl + '', config: AxiosRequestConfig = {}): Promise<T> {
    return this.get<Partial<T>>(path + (uid !== 0 ? '/' + uid : ''), config, this.getRequestSettings)
      .then(({ data }) => this.denormalizeItem(data))
  }

  public pushItem (entity: T, path: string = this.defaultCollectionUrl + ''): Promise<T> {
    if (entity.uid) {
      return this.patch<Partial<T>>(path + '/' + entity.uid, entity)
        .then(({ data }) => this.denormalizeItem(data))
    } else {
      return this.post<Partial<T>>(path, entity)
        .then(({ data }) => this.denormalizeItem(data))
    }
  }

  public deleteItem (entity: T, path: string = this.defaultCollectionUrl + ''): Promise<unknown> {
    return this.delete<T>(path + '/' + entity.uid)
  }

  protected denormalizeItem (data: Record<string, unknown>): T {
    return this.normalizerService.denormalize<T>(data, this.model)
  }

  protected get getRequestSettings (): GetRequestSettings {
    return {
      useResponseCache: this.useResponseCache,
      useSharedPromises: this.useSharedPromises
    }
  }
}
