
import { BaseComponent } from '@/components/base-component'
import { Options } from 'vue-class-component'

@Options({})
export default class Label extends BaseComponent {
  public get isEmpty (): boolean {
    return !!this.$slots.defualt
  }
}
