
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import { Inject } from 'inversify-props'
import { RoomService } from '@/services/room.service'
import { Room } from '@/models/room'
import CarouselImageSelector from '@/components/Form/Input/CarouselImageSelector.vue'
import { CarouselImageItem } from '@/interfaces/carousel-image-item'

type RoomCarouselImageItem = CarouselImageItem<Room>

@Options({
  components: { CarouselImageSelector },
  props: baseStepComponentProps
})
export default class RoomSelection extends BaseStepComponent {
  @Inject() private roomService!: RoomService

  public get roomsAsCarouselImageItems (): Promise<RoomCarouselImageItem[]> {
    return this.roomService.fetchAllWithDefaultSorting()
      .then(
        rooms => rooms.map(room => ({
          imageUrl: room.previewImageSmUrl || room.previewImageMdUrl || room.previewImage?.url,
          value: room,
          title: room.title
        }))
      )
  }
}
