import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class HydraErrorResponse {
  @Expose({
    name: 'hydra:code',
    toClassOnly: true
  })
  public code!: number

  @Expose({
    name: 'hydra:description',
    toClassOnly: true
  })
  public description!: string

  @Expose({
    name: 'hydra:title',
    toClassOnly: true
  })
  public title!: string
}
