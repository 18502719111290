import { EntityService } from '@/services/entity.service'
import { Room } from '@/models/room'
import { Filter } from '@/models/filter'
import { PreviewImage } from '@/models/preview-image'

export class RoomService extends EntityService<Room> {
  protected model = Room
  protected defaultCollectionUrl = 'curtains-configurator/rooms'
  protected useSharedPromises = true
  protected useResponseCache = true

  public fetchAllWithDefaultSorting (): Promise<Room[]> {
    const filter = new Filter({ itemsPerPage: 99999 })
    filter.order.add('sorting', 'asc')

    return super.fetchCollection(filter)
  }

  public fetchCurtainsToRoomByRoom (room: Room): Promise<PreviewImage[]> {
    return this.fetchItem(room.uid).then(room => room.previewImages)
  }
}
