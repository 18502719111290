import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3bea01d5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "size-configuration" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-5" }
const _hoisted_4 = { class: "col-5 offset-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideSelector = _resolveComponent("SideSelector")!
  const _component_NumberInput = _resolveComponent("NumberInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SideSelector, {
      modelValue: _ctx.configuratorSnapshot.curtainSide,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.configuratorSnapshot.curtainSide) = $event)),
      label: _ctx.$t('side')
    }, null, 8, ["modelValue", "label"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_NumberInput, {
          modelValue: _ctx.configuratorSnapshot.curtainWidthInCentimeters,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.configuratorSnapshot.curtainWidthInCentimeters) = $event)),
          label: _ctx.$t('width'),
          suffix: _ctx.$t('cm')
        }, null, 8, ["modelValue", "label", "suffix"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_NumberInput, {
          modelValue: _ctx.configuratorSnapshot.curtainHeightInCentimeters,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.configuratorSnapshot.curtainHeightInCentimeters) = $event)),
          label: _ctx.$t('height'),
          suffix: _ctx.$t('cm')
        }, null, 8, ["modelValue", "label", "suffix"])
      ])
    ])
  ]))
}