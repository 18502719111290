import { SelectBoxItemValue } from '@/interfaces/select-box-item'

export class WrinkleNumberOfFolds implements SelectBoxItemValue {
  // eslint-disable-next-line no-useless-constructor
  public constructor (public value: number) {
  }

  public get uniqueIdentifier (): string {
    return `WrinkleNumberOfFolds/${this.value}`
  }
}
