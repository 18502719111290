import { EntityService } from '@/services/entity.service'
import { AppConfig } from '@/models/app-config'
import { AppConfigApiResponse } from '@/interfaces/api/response/app-config-api-response'

export class AppConfigService extends EntityService<AppConfig> {
  protected model = AppConfig
  protected defaultCollectionUrl = 'curtains-configurator/app-configs'

  public async fetch (): Promise<AppConfig> {
    return this.get<Partial<AppConfigApiResponse>>(`${this.defaultCollectionUrl}/current`)
      .then(({ data }) => this.denormalizeItem(data))
  }
}
