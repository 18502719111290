import { Inject } from 'inversify-props'
import { RoomService } from '@/services/room.service'

export class PrefetchingService {
  @Inject() private roomService!: RoomService

  public prefetchAll (): void {
    this.roomService.fetchAllWithDefaultSorting().then(rooms => {
      rooms.forEach(room => this.roomService.fetchItem(room.uid))
    })
  }
}
