
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { TilesImageItem, TilesImageItemValue } from '@/interfaces/tiles-image-item'
import { PropType } from 'vue'
import Label from '@/components/Form/Label.vue'

type PropItems = Promise<TilesImageItem[]> | TilesImageItem[];

@Options({
  components: {
    Label
  },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    items: prop({
      type: Object as PropType<PropItems>,
      required: true,
      validator: (value: unknown): boolean => Array.isArray(value) || value instanceof Promise
    }),
    modelValue: prop({
      type: Object as PropType<TilesImageItemValue>,
      required: false
    }),
    preselectFirst: prop({
      type: Boolean,
      default: true
    }),
    showTitle: prop({
      type: Boolean,
      default: true
    })
  },
  watch: {
    selectedItem: function (newItem: TilesImageItem | null, previousItem: TilesImageItem | null) {
      if (this.isSameItem(newItem, previousItem)) {
        return
      }
      this.$emit('update:modelValue', newItem ? newItem.value : null)
    },
    items: function () {
      this.preselectItem()
      this.setItemsCollection()
    }
  }
})
export default class TilesImageSelector extends BaseComponent {
  public readonly items!: PropItems
  public itemsCollection: TilesImageItem[] | null = null
  private readonly modelValue!: TilesImageItemValue | null
  private readonly preselectFirst!: boolean
  private selectedItem: TilesImageItem | null = null

  public created (): void {
    this.preselectItem()
    this.setItemsCollection()
  }

  public isSelectedItem (item: TilesImageItem): boolean {
    return this.isSameValue(item.value, this.modelValue)
  }

  public toggleSelection (item: TilesImageItem): void {
    this.selectedItem = this.isSameItem(this.selectedItem, item) ? null : item
  }

  public isSameItem (itemA: TilesImageItem | null, itemB: TilesImageItem | null): boolean {
    if (itemA === itemB) {
      return true
    }

    if (itemA === null || itemB === null) {
      return false
    }

    return this.isSameValue(itemA.value, itemB.value)
  }

  private preselectItem (): void {
    this.itemsAsPromise().then(items => {
      this.selectedItem = this.modelValue ? (items.find(sliderItem => this.isSameValue(sliderItem.value, this.modelValue)) || null) : this.selectedItem

      if (!this.selectedItem && this.preselectFirst && items[0]) {
        this.selectedItem = items[0]
      }
    })
  }

  private setItemsCollection (): void {
    this.itemsAsPromise().then(items => {
      this.itemsCollection = items
    })
  }

  private isSameValue (valueA: TilesImageItemValue | null, valueB: TilesImageItemValue | null): boolean {
    if (valueA === null || valueB === null) {
      return valueA === valueB
    }
    return valueA.uniqueIdentifier === valueB.uniqueIdentifier
  }

  private itemsAsPromise (): Promise<TilesImageItem[]> {
    if (this.items instanceof Promise) {
      return this.items
    }

    return Promise.resolve(this.items)
  }
}
