import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { FileReference } from '@/models/file-reference'

@Exclude()
export class Color extends Entity {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  public readonly colorHexCode!: string

  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly previewImage!: FileReference
}
