import { Entity } from '@/models/entity'
import { Exclude, Expose } from 'class-transformer'

@Exclude()
export class File extends Entity {
  @Expose({ toClassOnly: true })
  public readonly name!: string

  @Expose({ toClassOnly: true })
  public readonly mimeType!: string
}
