
import { BaseComponent } from '@/components/base-component'
import { Options } from 'vue-class-component'
import BaseButton, { publicProps } from '@/components/Ui/Button/BaseButton.vue'

@Options({
  components: {
    BaseButton
  },
  props: publicProps
})
export default class LinkButton extends BaseComponent {
}
