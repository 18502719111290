import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-154ef683"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "curtain-preview",
  ref: "wrapperElement"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.error)
      ? (_openBlock(), _createBlock(_component_PrimaryButton, {
          key: 0,
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.restartConfigurator()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('previewErrorStartOver')), 1)
          ]),
          _: 1
        }))
      : (_ctx.previewImage)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.previewImage.imageMdUrl,
            class: "curtain-preview__image",
            alt: ""
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
  ], 512))
}