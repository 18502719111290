
import { Options, Vue } from 'vue-class-component'
import { Inject } from 'inversify-props'
import { StylesService } from '@/services/styles.service'
import { LanguageService } from '@/services/language.service'
import { Language } from '@/models/language'
import LinkButton from '@/components/Ui/Button/LinkButton.vue'

@Options({
  components: {
    LinkButton
  }
})
export default class App extends Vue {
  @Inject() private stylesService!: StylesService
  @Inject() private languageService!: LanguageService

  public get availableLanguages (): Language[] {
    return this.languageService.allAvailable
  }

  public get currentLanguage (): Language {
    return this.availableLanguages.find(availableLanguage => availableLanguage.code === this.languageService.currentCode) ||
      this.languageService.default ||
      this.availableLanguages[0]
  }

  created (): void {
    this.stylesService.applyFromAppConfig(this.$ccAppConfig)
    this.setAppTitle()
  }

  public changeLanguage (language: Language): void {
    this.languageService.selectLanguage(language)
    location.reload()
  }

  private setAppTitle (): void {
    const titleElement = Array.from(document.getElementsByTagName('title'))[0]

    if (titleElement) {
      titleElement.innerHTML = this.$ccAppConfig.title
    }
  }
}
