import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { FileReference } from '@/models/file-reference'
import { Room } from '@/models/room'
import { Curtain } from '@/models/curtain'
import { Wrinkle } from '@/models/wrinkle'

@Exclude()
export class PreviewImage extends Entity {
  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly image!: FileReference

  @Expose({ toClassOnly: true })
  public readonly imageMdUrl!: string

  @Expose({ toClassOnly: true })
  public readonly imageSmUrl!: string

  // @todo think about circular dependency. Maybe better to use interface?
  @Expose({ toClassOnly: true })
  @Type(() => Room)
  public readonly room!: Room

  // @todo think about circular dependency. Maybe better to use interface?
  @Expose({ toClassOnly: true })
  @Type(() => Curtain)
  public readonly curtain!: Curtain

  // @todo think about circular dependency. Maybe better to use interface?
  @Expose({ toClassOnly: true })
  @Type(() => Wrinkle)
  public readonly wrinkle!: Wrinkle
}
