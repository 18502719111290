export default {
  "error": {
    "couldNotInitializeAppDueMissingConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not initialize application (missing configuration)"])}
  },
  "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurator"])},
  "introMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your dream curtain with the curtain configurator. Put together your individual curtain directly on the computer."])},
  "loadOrder": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you already have a configuration and want to order?"])},
    "introButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue order"])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue order"])},
    "description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Enter your ", _interpolate(_named("codeLength")), "-character code here and continue the order."])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Are you sure such order exists?"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order loaded successfully"])},
    "submitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])}
  },
  "copyOrderCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish later? No problem - save the code and place your order later:"])},
  "saveOrderError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred during saving order. Please try again."])},
  "saveOrderSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order saved successfully"])},
  "roomStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
  "roomSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For which room do you need a curtain?"])},
  "textileStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textile"])},
  "textileSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency"])},
  "textileNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "textileFeatureSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characteristics"])},
  "textileFeatureNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinkleStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrinkles"])},
  "wrinklesFrequencyNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
  "wrinkleSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of wrinkles do they want?"])},
  "wrinkleDistanceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How big should the distance between the folds be?"])},
  "wrinkleTypesSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "wrinkleTypesNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinkleNumberOfFoldsSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of folds"])},
  "wrinkleNumberOfFoldsNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinkleColorSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])},
  "wrinkleColorNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinkleTextileRatioSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern repetition"])},
  "wrinkleTextileRatioNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinkleWidthSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width (mm)"])},
  "wrinkleWidthNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "wrinklePatternRepetitionDistanceSelectFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport (mm)"])},
  "wrinklePatternRepetitionDistanceNoSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "sizeStepName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
  "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish"])},
  "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
  "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height"])},
  "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cm"])},
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
  "missingPreviewImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No image"])},
  "millimetersSuffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" mm"])},
  "previewErrorStartOver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred. Please start from the beginning."])}
}