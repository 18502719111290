
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { RouteLocationRaw } from 'vue-router'
import { PropType } from 'vue'

export const publicProps = {
  linkToRoute: prop({
    type: Object as PropType<RouteLocationRaw>,
    required: false
  }),
  inactive: prop({
    type: Boolean,
    default: false
  })
}

enum EmitKey {
  Click = 'button-click'
}

enum HorizontalPaddingOptions {
  Md = 'md',
  L = 'l',
}

@Options({
  props: {
    ...publicProps,
    bgColor: prop({
      type: String,
      required: false
    }),
    horizontalPadding: prop({
      type: String,
      default: HorizontalPaddingOptions.Md
    })
  },
  emits: [EmitKey.Click]
})
export default class BaseButton extends BaseComponent {
  private inactive!: boolean
  private bgColor!: string
  private horizontalPadding!: HorizontalPaddingOptions

  public get classes (): string[] {
    const classes = ['button']

    classes.push('button--hp-' + this.horizontalPadding)

    if (this.inactive) {
      classes.push('button--inactive')
    }

    return classes
  }

  public get cssVars (): Record<string, string> {
    return {
      '--button-bg': this.bgColor
    }
  }

  public onClick (): void {
    if (this.inactive) {
      return
    }

    this.$emit(EmitKey.Click)
  }
}
