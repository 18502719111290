import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { FileReference } from '@/models/file-reference'
import { TilesImageItemValue } from '@/interfaces/tiles-image-item'

@Exclude()
export class Pattern extends Entity implements TilesImageItemValue {
  @Expose({ toClassOnly: true })
  public readonly title!: string

  @Expose({ toClassOnly: true })
  @Type(() => FileReference)
  public readonly previewImage!: FileReference

  @Expose({ toClassOnly: true })
  public readonly sorting!: number

  public get uniqueIdentifier (): string {
    return this.id || `${this.constructor.name}/${this.uid}`
  }
}
