import './styles/main.scss'
import { createInstance } from './app.container'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { App as VueApp } from '@vue/runtime-core'
import { AppConfigService } from '@/services/app-config.service'
import { AppConfig } from '@/models/app-config'
import { Inject } from 'inversify-props'
import { createI18n } from 'vue-i18n'
import { LanguageService } from '@/services/language.service'
import vfmPlugin from 'vue-final-modal'
import Notifications from '@kyvg/vue3-notification'

class Main {
  @Inject() private appConfigService!: AppConfigService
  @Inject() private languageService!: LanguageService
  private readonly rootContainerSelector = '#app'
  private _app: VueApp | null = null
  private _appConfig: AppConfig | null = null

  private get app (): VueApp {
    if (!this._app) {
      throw new Error('app is not initialized. Did you run initialize method? #1662555341660')
    }

    return this._app
  }

  private get appConfig (): AppConfig {
    if (!this._appConfig) {
      throw new Error('appConfig is not initialized. Did you run initialize method? #1662564181989')
    }

    return this._appConfig
  }

  public initialize (): void {
    this._app = createApp(App)
    this.appConfigService.fetch()
      .then(appConfig => {
        this._appConfig = appConfig
      })
      .then(() => this.doInitialize())
  }

  private doInitialize () {
    this.installI18n()
    this.installRouter()
    this.installVueFinalModal()
    this.installNotifications()
    this.registerAppConfigAsGlobalVariable()
    this.mount()
  }

  private installI18n (): void {
    this.languageService.appConfig = this.appConfig
    this.app.use(createI18n({
      legacy: false,
      locale: this.languageService.currentCode,
      fallbackLocale: 'en',
      messages: this.languageService.messages
    }))
  }

  private installRouter (): void {
    this.app.use(router)
  }

  private installVueFinalModal (): void {
    // Unfortunately was not able to use import of the component ONLY inside our HOC component (as described in
    // recommendation https://v3.vue-final-modal.org/examples/recommend) because receiving an error about undefined "parentNode".
    // That's the only reason (right now) why this plugin is installed globally.
    this.app.use(vfmPlugin)
  }

  private installNotifications (): void {
    this.app.use(Notifications)
    this.registerNotifyShortcut('$notifyError', 'error')
    this.registerNotifyShortcut('$notifySuccess', 'success')
    this.registerNotifyShortcut('$notifyInfo', 'info')
  }

  private registerNotifyShortcut (key: string, type: string): void {
    this.app.config.globalProperties[key] = function (message: string, title?: string) {
      this.$notify({
        title,
        text: message,
        type
      })
    }
  }

  private registerAppConfigAsGlobalVariable (): void {
    this.app.config.globalProperties.$ccAppConfig = this.appConfig
  }

  private mount (): void {
    this.app.mount(this.rootContainerSelector)
  }
}

createInstance<Main>(Main).initialize()
