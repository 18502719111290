
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import SelectBox from '@/components/Form/Input/SelectBox.vue'
import CarouselImageSelector from '@/components/Form/Input/CarouselImageSelector.vue'
import { Inject } from 'inversify-props'
import { WrinkleType } from '@/models/wrinkle-type'
import { SelectBoxItem } from '@/interfaces/select-box-item'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'
import { WrinkleTextileRatio } from '@/models/wrinkle-textile-ratio'
import { WrinkleColor } from '@/models/wrinkle-color'
import { WrinkleNumberOfFolds } from '@/models/wrinkle-number-of-folds'
import { WrinklePatternRepetitionDistance } from '@/models/wrinkle-pattern-repetition-distance'
import { WrinkleWidth } from '@/models/wrinkle-width'
import TilesImageSelector from '@/components/Form/Input/TilesImageSelector.vue'
import { TilesImageItem } from '@/interfaces/tiles-image-item'
import { Wrinkle } from '@/models/wrinkle'

type WrinkleTypeSelectBoxItem = SelectBoxItem<WrinkleType>
type WrinkleNumberOfFoldsSelectBoxItem = SelectBoxItem<WrinkleNumberOfFolds>
type WrinkleColorSelectBoxItem = SelectBoxItem<WrinkleColor>
type WrinkleTextileRatioSelectBoxItem = SelectBoxItem<WrinkleTextileRatio>
type WrinkleWidthInMillimetersSelectBoxItem = SelectBoxItem<WrinkleWidth>
type WrinklePatternRepetitionDistanceInMillimetersSelectBoxItem = SelectBoxItem<WrinklePatternRepetitionDistance>
type WrinklesAsTilesImageItem = TilesImageItem<Wrinkle>

@Options({
  components: {
    TilesImageSelector,
    SelectBox,
    CarouselImageSelector
  },
  props: baseStepComponentProps,
  watch: {
    'configuratorSnapshot.selectedWrinkleType' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleNumberOfFolds' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleColor' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleTextileRatio' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinkleWidthInMillimeters' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedWrinklePatternRepetitionDistanceInMillimeters' () {
      this.setWrinklesAsTilesImageItems()
      this.setFiltersValues()
    }
  }
})
export default class WrinkleConfiguration extends BaseStepComponent {
  @Inject() private readonly availablePreviewImageService!: AvailablePreviewImageService
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public wrinkleTypesAsSelectBoxItems: WrinkleTypeSelectBoxItem[] = []
  public wrinkleNumberOfFoldsAsSelectBoxItems: WrinkleNumberOfFoldsSelectBoxItem[] = []
  public wrinkleColorsAsSelectBoxItems: WrinkleColorSelectBoxItem[] = []
  public wrinkleTextileRatiosAsSelectBoxItems: WrinkleTextileRatioSelectBoxItem[] = []
  public wrinkleWidthInMillimetersAsSelectBoxItems: WrinkleWidthInMillimetersSelectBoxItem[] = []
  public wrinklePatternRepetitionDistanceInMillimetersAsSelectBoxItems: WrinklePatternRepetitionDistanceInMillimetersSelectBoxItem[] = []
  public wrinklesAsTilesImageItems: WrinklesAsTilesImageItem[] = []

  public created (): void {
    this.setWrinklesAsTilesImageItems()
    this.setFiltersValues()
  }

  private setWrinklesAsTilesImageItems (): void {
    this.getAvailableDistinctWrinkles()
      .then(wrinkles => {
        this.wrinklesAsTilesImageItems = wrinkles
          .sort((wrinkleA, wrinkleB) => wrinkleA.sorting - wrinkleB.sorting)
          .map(wrinkle => ({
            value: wrinkle,
            title: wrinkle.title,
            imageUrl: wrinkle.previewImage?.url
          }))
      })
  }

  private getAvailableDistinctWrinkles (): Promise<Wrinkle[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, (AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.Wrinkle))
      .then(previewImages => {
        const wrinkles = previewImages
          .filter(previewImage => !!previewImage.wrinkle)
          .map(previewImage => previewImage.wrinkle)

        return [...new Map(wrinkles.map(wrinkle => [wrinkle.uid, wrinkle])).values()]
      })
  }

  private setFiltersValues (): void {
    this.setWrinkleTypesAsSelectBoxItems()
    this.setWrinkleNumberOfFoldsAsSelectBoxItems()
    this.setWrinkleColorsAsSelectBoxItems()
    this.setWrinkleTextileRatiosAsSelectBoxItems()
    this.setWrinkleWidthsAsSelectBoxItems()
    this.setWrinklePatternRepetitionDistancesAsSelectBoxItems()
  }

  private setWrinkleTypesAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinkleTypes()
      .then(wrinkleTypes => {
        this.wrinkleTypesAsSelectBoxItems = wrinkleTypes
          .sort((wrinkleTypeA, wrinkleTypeB) => wrinkleTypeA.sorting - wrinkleTypeB.sorting)
          .map(wrinkleType => ({
            value: wrinkleType,
            title: wrinkleType.title
          }))
      })
  }

  private getAvailableDistinctWrinkleTypes (): Promise<WrinkleType[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleType ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const wrinkleTypes = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.type)
          .map(previewImage => previewImage.wrinkle?.type)

        return [...new Map(wrinkleTypes.map(wrinkleType => [wrinkleType.uid, wrinkleType])).values()]
      })
  }

  private setWrinkleNumberOfFoldsAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinkleNumberOfFolds()
      .then(numberOfFolds => {
        this.wrinkleNumberOfFoldsAsSelectBoxItems = numberOfFolds.sort().map(numberOfFolds => ({
          value: new WrinkleNumberOfFolds(numberOfFolds),
          title: numberOfFolds.toString()
        }))
      })
  }

  private getAvailableDistinctWrinkleNumberOfFolds (): Promise<number[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleNumberOfFolds ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const numberOfFolds = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.numberOfFolds)
          .map(previewImage => previewImage.wrinkle?.numberOfFolds)

        return [...new Set(numberOfFolds)]
      })
  }

  private setWrinkleColorsAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinkleColors()
      .then(wrinkleColors => {
        this.wrinkleColorsAsSelectBoxItems = wrinkleColors
          .sort((wrinkleColorA, wrinkleColorB) => wrinkleColorA.sorting - wrinkleColorB.sorting)
          .map(wrinkleColor => ({
            value: wrinkleColor,
            title: wrinkleColor.title
          }))
      })
  }

  private getAvailableDistinctWrinkleColors (): Promise<WrinkleColor[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleColor ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const wrinkleColors = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.color)
          .map(previewImage => previewImage.wrinkle?.color)

        return [...new Map(wrinkleColors.map(wrinkleColor => [wrinkleColor.uid, wrinkleColor])).values()]
      })
  }

  private setWrinkleTextileRatiosAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinkleTextileRatios()
      .then(wrinkleTextileRatios => {
        this.wrinkleTextileRatiosAsSelectBoxItems = wrinkleTextileRatios
          .sort((wrinkleTextileRatioA, wrinkleTextileRatioB) => wrinkleTextileRatioA.sorting - wrinkleTextileRatioB.sorting)
          .map(wrinkleTextileRatio => ({
            value: wrinkleTextileRatio,
            title: wrinkleTextileRatio.title
          }))
      })
  }

  private getAvailableDistinctWrinkleTextileRatios (): Promise<WrinkleTextileRatio[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleTextileRatio ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const wrinkleTextileRatios = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.textileRatio)
          .map(previewImage => previewImage.wrinkle?.textileRatio)

        return [...new Map(wrinkleTextileRatios.map(wrinkleTextileRatio => [wrinkleTextileRatio.uid, wrinkleTextileRatio])).values()]
      })
  }

  private setWrinkleWidthsAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinkleWidths()
      .then(widths => {
        this.wrinkleWidthInMillimetersAsSelectBoxItems = widths.sort().map(width => ({
          value: new WrinkleWidth(width),
          title: width.toString()
        }))
      })
  }

  private getAvailableDistinctWrinkleWidths (): Promise<number[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleWidth ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const widths = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.widthInMillimeters)
          .map(previewImage => previewImage.wrinkle?.widthInMillimeters)

        return [...new Set(widths)]
      })
  }

  private setWrinklePatternRepetitionDistancesAsSelectBoxItems (): void {
    this.getAvailableDistinctWrinklePatternRepetitionDistances()
      .then(patternRepetitionDistances => {
        this.wrinklePatternRepetitionDistanceInMillimetersAsSelectBoxItems = patternRepetitionDistances.sort().map(patternRepetitionDistance => ({
          value: new WrinklePatternRepetitionDistance(patternRepetitionDistance),
          title: patternRepetitionDistance.toString()
        }))
      })
  }

  private getAvailableDistinctWrinklePatternRepetitionDistances (): Promise<number[]> {
    if (!this.configuratorSnapshot.selectedRoom) {
      return Promise.resolve([])
    }

    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.All ^ AvailablePreviewImageFilter.WrinkleWidth ^ AvailablePreviewImageFilter.Wrinkle)
      .then(previewImages => {
        const patternRepetitionDistances = previewImages
          .filter(previewImage => !!previewImage.wrinkle?.patternRepetitionDistanceInMillimeters)
          .map(previewImage => previewImage.wrinkle?.patternRepetitionDistanceInMillimeters)

        return [...new Set(patternRepetitionDistances)]
      })
  }
}
