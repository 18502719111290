import { Entity } from '@/models/entity'
import { Exclude, Expose, Type } from 'class-transformer'
import { File } from '@/models/file'

@Exclude()
export class FileReference extends Entity {
  @Expose({ toClassOnly: true })
  public readonly url!: string

  @Expose({ toClassOnly: true })
  @Type(() => File)
  public readonly file!: File
}
