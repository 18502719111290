
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import Label from '@/components/Form/Label.vue'

@Options({
  components: { Label },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    maxLength: prop({
      type: Number,
      default: null
    }),
    modelValue: prop({
      type: String,
      required: true
    })
  }
})
export default class TextInput extends BaseComponent {
  private readonly modelValue!: string

  public get value (): string {
    return this.modelValue
  }

  public set value (value: string) {
    this.$emit('update:modelValue', value)
  }
}
