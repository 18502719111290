import { Exclude, Expose } from 'class-transformer'

@Exclude()
export abstract class Entity {
  @Expose({ name: '@id' })
  public readonly id!: string | undefined

  @Expose()
  public readonly uid!: number
}
