type SharedKey = string
type PromiseOutput = unknown
type SharedValue = {
  // Promise which will be really executed
  executablePromise: Promise<PromiseOutput>,
  // Promises which will be delivered using executablePromise output
  awaitingPromises: ((input: PromiseOutput) => Promise<PromiseOutput>)[]
}

/**
 * This service allows to optimize redundant promises.
 * It groups them by specific key and do real execution only of the first one. The rest of promises will be delivered
 * only by using output of the first (really executed) promise.
 *
 * It's useful e.g. to protect against making multiple exactly the same request to the API.
 *
 * Usage:
 * Instead of standard call of the promise like:
 * ```
 * fetch('https://www.example.com/api').then(response => console.log('firstResponse', response))
 * fetch('https://www.example.com/api').then(response => console.log('secondResponse', response))
 * ```
 *
 * Just do it like that:
 * ```
 * sharedPromiseService.resolve('my-custom-key', () => fetch('https://www.example.com/api')).then((response => console.log('firstResponse', response))
 * sharedPromiseService.resolve('my-custom-key', () => fetch('https://www.example.com/api')).then(response => console.log('secondResponse', response))
 * ```
 *
 * The output will be exactly the same but only one request to the API will be done.
 */
export class SharedPromiseService {
  private promisesInProgress: Map<SharedKey, SharedValue> = new Map()

  public resolve<T extends PromiseOutput> (key: SharedKey, promiseCallback: () => Promise<T>): Promise<T> {
    if (!this.promisesInProgress.has(key)) {
      const executablePromise = promiseCallback()
        .then((realOutput) => {
          this.executeAwaitingPromisesAsync<T>(key, realOutput)
          return realOutput
        })

      this.initializeGroup<T>(key, executablePromise)

      return executablePromise
    }

    const awaitingPromises = this.promisesInProgress.get(key)?.awaitingPromises || []

    return new Promise((resolve) => awaitingPromises.push(resolve as () => Promise<T>))
  }

  /**
   * It works asynchronously using setTimeout to be sure that awaitingPromises are delivered AFTER executablePromise,
   * which firstly occurred in runtime
   */
  private executeAwaitingPromisesAsync<T extends PromiseOutput> (key: SharedKey, realOutput: T): void {
    const awaitingPromises = this.promisesInProgress.get(key)?.awaitingPromises || []
    setTimeout(() => {
      awaitingPromises.forEach(callback => callback(realOutput))
      this.promisesInProgress.delete(key)
    }, 0)
  }

  private initializeGroup<T extends PromiseOutput> (key: SharedKey, executablePromise: Promise<T>): void {
    this.promisesInProgress.set(key, {
      executablePromise,
      awaitingPromises: []
    })
  }
}
