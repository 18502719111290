import { AppConfig } from '@/models/app-config'

export class StylesService {
  private static readonly customVariablePrefix = '--app-'

  public applyFromAppConfig (appConfig: AppConfig): void {
    appConfig.styleVariables.forEach((value, name) => {
      window.document.documentElement.style.setProperty(StylesService.customVariablePrefix + name, value)
    })
  }
}
