import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a2fb73c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_ctx.configuratorSnapshot.code)
    ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('copyOrderCode')) + " ", 1),
        _createVNode(_component_LinkButton, {
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyCode()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.configuratorSnapshot.code), 1)
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}