import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cec75fa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_ctx.linkToRoute)
    ? (_openBlock(), _createBlock(_component_RouterLink, {
        key: 0,
        to: !_ctx.inactive ? _ctx.linkToRoute : '',
        class: _normalizeClass(_ctx.classes),
        style: _normalizeStyle(_ctx.cssVars),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick()))
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["to", "class", "style"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: _normalizeClass(_ctx.classes),
        style: _normalizeStyle(_ctx.cssVars),
        disabled: _ctx.inactive,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick()))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 14, _hoisted_1))
}