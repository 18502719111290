import { Entity } from '@/models/entity'
import { Exclude, Expose, Transform, Type } from 'class-transformer'
import { Room } from '@/models/room'
import { Wrinkle } from '@/models/wrinkle'
import { TextileFeature } from '@/models/textile-feature'
import { Transparency } from '@/models/transparency'
import { Side } from '@/enums/side'
import { WrinkleType } from '@/models/wrinkle-type'
import { WrinkleColor } from '@/models/wrinkle-color'
import { WrinkleTextileRatio } from '@/models/wrinkle-textile-ratio'
import { WrinkleNumberOfFolds } from '@/models/wrinkle-number-of-folds'
import { WrinkleWidth } from '@/models/wrinkle-width'
import { WrinklePatternRepetitionDistance } from '@/models/wrinkle-pattern-repetition-distance'
import { Pattern } from '@/models/pattern'
import { Curtain } from '@/models/curtain'

@Exclude()
export class ConfiguratorSnapshot extends Entity {
  @Expose({ toClassOnly: true })
  public code: string | null = null

  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  public curtainSide: Side = Side.Both

  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  public curtainWidthInCentimeters = 0

  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  public curtainHeightInCentimeters = 0

  @Type(() => TextileFeature)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value: textileFeatures }: { value: TextileFeature[] }) => textileFeatures.map(textileFeature => textileFeature.uid), {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedTextileFeatures: TextileFeature[] = []

  @Type(() => Room)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Room }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedRoom: Room | null = null

  @Type(() => Curtain)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Curtain }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedCurtain: Curtain | null = null

  @Type(() => Pattern)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Pattern }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedPattern: Pattern | null = null

  @Type(() => Wrinkle)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedWrinkle: Wrinkle | null = null

  @Type(() => Wrinkle)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedTransparency: Transparency | null = null

  @Type(() => Wrinkle)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedWrinkleType: WrinkleType | null = null

  @Type(() => Wrinkle)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedWrinkleColor: WrinkleColor | null = null

  @Type(() => Wrinkle)
  @Expose({ groups: ['snapshot.create', 'snapshot.load'] })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  public selectedWrinkleTextileRatio: WrinkleTextileRatio | null = null

  @Type(() => Wrinkle)
  @Expose({
    name: 'selectedWrinkleNumberOfFolds',
    groups: ['snapshot.create', 'snapshot.load']
  })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  private _selectedWrinkleNumberOfFolds: number | null = null

  @Type(() => Wrinkle)
  @Expose({
    name: 'selectedWrinkleWidthInMillimeters',
    groups: ['snapshot.create', 'snapshot.load']
  })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  private _selectedWrinkleWidthInMillimeters: number | null = null

  @Type(() => Wrinkle)
  @Expose({
    name: 'selectedWrinklePatternRepetitionDistanceInMillimeters',
    groups: ['snapshot.create', 'snapshot.load']
  })
  @Transform(({ value }: { value: Wrinkle }) => value ? value.uid : null, {
    toPlainOnly: true,
    groups: ['snapshot.create', 'snapshot.load']
  })
  private _selectedWrinklePatternRepetitionDistanceInMillimeters: number | null = null

  public get selectedWrinkleNumberOfFolds (): WrinkleNumberOfFolds | null {
    return this._selectedWrinkleNumberOfFolds !== null
      ? new WrinkleNumberOfFolds(this._selectedWrinkleNumberOfFolds)
      : null
  }

  public set selectedWrinkleNumberOfFolds (value: WrinkleNumberOfFolds | null) {
    this._selectedWrinkleNumberOfFolds = value === null ? value : value.value
  }

  public get selectedWrinkleWidthInMillimeters (): WrinkleWidth | null {
    return this._selectedWrinkleWidthInMillimeters !== null
      ? new WrinkleWidth(this._selectedWrinkleWidthInMillimeters)
      : null
  }

  public set selectedWrinkleWidthInMillimeters (value: WrinkleWidth | null) {
    this._selectedWrinkleWidthInMillimeters = value === null ? value : value.value
  }

  public get selectedWrinklePatternRepetitionDistanceInMillimeters (): WrinklePatternRepetitionDistance | null {
    return this._selectedWrinklePatternRepetitionDistanceInMillimeters !== null
      ? new WrinklePatternRepetitionDistance(this._selectedWrinklePatternRepetitionDistanceInMillimeters)
      : null
  }

  public set selectedWrinklePatternRepetitionDistanceInMillimeters (value: WrinklePatternRepetitionDistance | null) {
    this._selectedWrinklePatternRepetitionDistanceInMillimeters = value === null ? value : value.value
  }
}
