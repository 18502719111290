import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-186f3fa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "step-navigator",
  ref: "wrapperElement"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step) => {
      return (_openBlock(), _createElementBlock("li", {
        key: step,
        class: "step-navigator__element"
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.canGoToStep(step) ? { name: 'configurator', params: { step: step }} : '',
          class: _normalizeClass(["step-navigator__item", {
                      'step-navigator__item--current': step === _ctx.currentStep,
                      'step-navigator__item--inactive': !_ctx.canGoToStep(step),
                    }])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(step + 'StepName')), 1)
          ]),
          _: 2
        }, 1032, ["to", "class"])
      ]))
    }), 128))
  ], 512))
}