import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'
import { Inject } from 'inversify-props'
import { NormalizerService } from '@/services/normalizer.service'
import { UrlService } from '@/services/url.service'
import { Filter } from '@/models/filter'
import { HttpGenericError } from '@/errors/http-generic.error'
import { HydraErrorResponse } from '@/models/hydra/hydra-error-response'
import { HydraErrorValidationResponse } from '@/models/hydra/hydra-error-validation-response'
import { LanguageService } from '@/services/language.service'
import { CacheService } from '@/services/cache.service'
import { SharedPromiseService } from '@/services/shared-promise.service'

type ApiResponseError = AxiosError<Record<string, unknown>>;

export type GetRequestSettings = {
  useSharedPromises?: boolean,
  useResponseCache?: boolean,
}

export abstract class ApiService {
  @Inject() protected normalizerService!: NormalizerService
  @Inject() protected languageService!: LanguageService
  @Inject() private cacheService!: CacheService<AxiosResponse>
  @Inject() private sharedPromiseService!: SharedPromiseService
  private http!: AxiosInstance

  protected constructor () {
    this.initializeHttpService()
  }

  protected async get<T> (
    url: string,
    config?: AxiosRequestConfig,
    settings?: GetRequestSettings
  ): Promise<AxiosResponse<T>> {
    // More logic should be put to generate unique identifier (e.g. take care about params order in config etc.) but
    // such solution is absolutely enough for curtains configurator app purposes
    const requestUniqueIdentifier = [url, this.languageService.currentCode, JSON.stringify(config || {})].join('/')

    if (settings?.useResponseCache && this.cacheService.has(requestUniqueIdentifier)) {
      return Promise.resolve(this.cacheService.get(requestUniqueIdentifier) as AxiosResponse<T>)
    }

    const executeRequest = () => this.doGet<T>(url, config)
      .then(response => {
        if (settings?.useResponseCache) {
          this.cacheService.set(requestUniqueIdentifier, response)
        }

        return response
      })

    if (settings?.useSharedPromises) {
      return this.sharedPromiseService.resolve(requestUniqueIdentifier, executeRequest)
    }

    return executeRequest()
  }

  protected async doGet<T> (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      return await this.http.get<T>(url, config)
    } catch (error) {
      if (error instanceof Error) {
        if ((error as AxiosError).isAxiosError) {
          throw this.mapHttpError(error as ApiResponseError)
        }
      }
      throw error
    }
  }

  protected async patch<T> (url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      return await this.http.patch<T>(url, data, config)
    } catch (error) {
      if (error instanceof Error) {
        if ((error as AxiosError).isAxiosError) {
          throw this.mapHttpError(error as ApiResponseError)
        }
      }
      throw error
    }
  }

  protected async post<T> (url: string, data?: unknown, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      return await this.http.post<T>(url, data, config)
    } catch (error) {
      if (error instanceof Error) {
        if ((error as AxiosError).isAxiosError) {
          throw this.mapHttpError(error as ApiResponseError)
        }
      }
      throw error
    }
  }

  protected async delete<T> (url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      return await this.http.delete<T>(url, config)
    } catch (error) {
      if (error instanceof Error) {
        if ((error as AxiosError).isAxiosError) {
          throw this.mapHttpError(error as ApiResponseError)
        }
      }
      throw error
    }
  }

  private mapHttpError (error: ApiResponseError): Error {
    if (error.response?.data) {
      // with more kinds of errors this should be refactored as a factory:
      const res = error.response.data?.violations
        ? HttpGenericError.fromHydraErrorResponse(this.normalizerService.denormalize<HydraErrorValidationResponse>(error.response.data, HydraErrorValidationResponse))
        : HttpGenericError.fromHydraErrorResponse(this.normalizerService.denormalize<HydraErrorResponse>(error.response.data, HydraErrorResponse))
      res.response = error.response.data

      return res
    }

    return error
  }

  private initializeHttpService (): void {
    this.http = axios.create({
      baseURL: UrlService.apiBaseUrl,
      withCredentials: true
    })
    this.registerLanguageHeaderInterceptor()
    this.registerHttpServiceRequestParamsSerializerInterceptor()
  }

  /**
   * https://github.com/axios/axios/issues/738#issuecomment-412905574
   */
  private registerHttpServiceRequestParamsSerializerInterceptor (): void {
    this.http.interceptors.request.use((config: AxiosRequestConfig) => {
      config.paramsSerializer = (params: Record<string, unknown> | Filter) => {
        return qs.stringify(this.normalizerService.normalize(params), {
          arrayFormat: 'brackets',
          encode: true
        })
      }

      return config
    })
  }

  private registerLanguageHeaderInterceptor (): void {
    this.http.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers = {
        ...config.headers || {},
        'X-Locale': this.languageService.currentCode
      }

      return config
    })
  }
}
