
import { Options } from 'vue-class-component'
import { BaseStepComponent, baseStepComponentProps } from '@/components/CurtainConfigurator/base-step-component'
import { ConfiguratorSnapshot } from '@/models/configurator-snapshot'
import { SelectBoxItem } from '@/interfaces/select-box-item'
import { Transparency } from '@/models/transparency'
import { Inject } from 'inversify-props'
import { AvailablePreviewImageService } from '@/services/available-preview-image.service'
import { AvailablePreviewImageFilter } from '@/enums/available-preview-image-filter'
import SelectBox from '@/components/Form/Input/SelectBox.vue'
import { TextileFeature } from '@/models/textile-feature'
import { TilesImageItem } from '@/interfaces/tiles-image-item'
import { Pattern } from '@/models/pattern'
import TilesImageSelector from '@/components/Form/Input/TilesImageSelector.vue'

type TextileFeatureSelectBoxItem = SelectBoxItem<TextileFeature>
type TransparencySelectBoxItem = SelectBoxItem<Transparency>
type PatternTilesImageItem = TilesImageItem<Pattern>

@Options({
  components: {
    SelectBox,
    TilesImageSelector
  },
  props: baseStepComponentProps,
  watch: {
    'configuratorSnapshot.selectedTextileFeatures' () {
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedTransparency' () {
      this.setFiltersValues()
    },
    'configuratorSnapshot.selectedPattern' () {
      this.setFiltersValues()
    }
  }
})
export default class CurtainConfiguration extends BaseStepComponent {
  @Inject() private readonly availablePreviewImageService!: AvailablePreviewImageService
  public readonly configuratorSnapshot!: ConfiguratorSnapshot
  public textileFeaturesAsSelectBoxItems: TextileFeatureSelectBoxItem[] | null = null
  public transparenciesAsSelectBoxItems: TransparencySelectBoxItem[] | null = null
  public patternsAsTilesImageItems: PatternTilesImageItem[] | null = null

  public created (): void {
    this.setFiltersValues()
  }

  private setFiltersValues (): void {
    this.setTextileFeaturesAsSelectBoxItems()
    this.setTransparenciesAsSelectBoxItems()
    this.setPatternsAsTilesImageItems()
  }

  private setTextileFeaturesAsSelectBoxItems (): void {
    this.getAvailableDistinctTextileFeaturesAsMap()
      .then(patterns => patterns.sort((patternA, patternB) => patternA.sorting - patternB.sorting))
      .then(patterns => {
        this.textileFeaturesAsSelectBoxItems = patterns.map(pattern => {
          return {
            value: pattern,
            title: pattern.title
          }
        })
      })
  }

  private getAvailableDistinctTextileFeaturesAsMap (): Promise<TextileFeature[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.TextileFeatures)
      .then(previewImage => {
        const distinctTextileFeatures: Map<number, TextileFeature> = new Map()
        previewImage.forEach(previewImage => {
          previewImage.curtain.textileFeatures.forEach(textileFeature => {
            distinctTextileFeatures.set(textileFeature.uid, textileFeature)
          })
        })

        return Array.from(distinctTextileFeatures.values())
      })
  }

  private setTransparenciesAsSelectBoxItems (): void {
    this.getAvailableDistinctTransparencies()
      .then(transparencies => transparencies.sort((transparencyA, transparencyB) => transparencyA.sorting - transparencyB.sorting))
      .then(transparencies => {
        this.transparenciesAsSelectBoxItems = transparencies.map(transparency => {
          return {
            value: transparency,
            title: transparency.title
          }
        })
      })
  }

  private getAvailableDistinctTransparencies (): Promise<Transparency[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.Transparency)
      .then(previewImages => {
        const transparencies = previewImages
          .filter(previewImage => !!previewImage.curtain?.transparency)
          .map(previewImage => previewImage.curtain.transparency)

        return Array.from(new Map(transparencies.map(transparency => [transparency.uid, transparency])).values())
      })
  }

  private setPatternsAsTilesImageItems (): void {
    this.getAvailableDistinctPatterns()
      .then(patterns => patterns.sort((patternA, patternB) => patternA.sorting - patternB.sorting))
      .then(patterns => {
        this.patternsAsTilesImageItems = patterns.map(pattern => ({
          value: pattern,
          title: pattern.title,
          imageUrl: pattern.previewImage?.url
        }))
      })
  }

  private getAvailableDistinctPatterns (): Promise<Pattern[]> {
    return this.availablePreviewImageService
      .fetchByConfigurationSnapshot(this.configuratorSnapshot, AvailablePreviewImageFilter.Step1 ^ AvailablePreviewImageFilter.Pattern)
      .then(previewImages => {
        const patterns = previewImages
          .filter(previewImage => !!previewImage.curtain?.pattern)
          .map(previewImage => previewImage.curtain.pattern)

        return Array.from(new Map(patterns.map(pattern => [pattern.uid, pattern])).values())
      })
  }
}
