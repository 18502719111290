// https:// stackoverflow.com/a/39359953/1588346
export enum AvailablePreviewImageFilter {
  None = 0,
  TextileFeatures = 1 << 0,
  Transparency = 1 << 1,
  Pattern = 1 << 2,
  WrinkleType = 1 << 3,
  WrinkleNumberOfFolds = 1 << 4,
  WrinkleColor = 1 << 5,
  WrinkleTextileRatio = 1 << 6,
  WrinkleWidth = 1 << 7,
  WrinklePatternRepetitionDistance = 1 << 8,
  Wrinkle = 1 << 9,
  Step1 = AvailablePreviewImageFilter.TextileFeatures |
    AvailablePreviewImageFilter.Transparency |
    AvailablePreviewImageFilter.Pattern,
  Step2 = AvailablePreviewImageFilter.WrinkleType |
    AvailablePreviewImageFilter.WrinkleNumberOfFolds |
    AvailablePreviewImageFilter.WrinkleColor |
    AvailablePreviewImageFilter.WrinkleTextileRatio |
    AvailablePreviewImageFilter.WrinkleWidth |
    AvailablePreviewImageFilter.WrinklePatternRepetitionDistance |
    AvailablePreviewImageFilter.Wrinkle,
  All = AvailablePreviewImageFilter.Step1 | AvailablePreviewImageFilter.Step2,
  AffectingPreviewImage = AvailablePreviewImageFilter.Transparency |
    AvailablePreviewImageFilter.Pattern |
    AvailablePreviewImageFilter.Wrinkle
}
