
import { BaseComponent } from '@/components/base-component'
import { Options, prop } from 'vue-class-component'
import { CarouselImageItem, CarouselImageItemValue } from '@/interfaces/carousel-image-item'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { PropType } from 'vue'
import Label from '@/components/Form/Label.vue'
import { Swiper as SwiperClass } from 'swiper/types'
import { SwiperOptions } from 'swiper/types/swiper-options'
import { ViewportBreakpoint } from '@/enums/viewport-breakpoint'

import 'swiper/css'

type PropItems = Promise<CarouselImageItem[]> | CarouselImageItem[];
type Slider = SwiperClass

const defaultBreakpointKey = ViewportBreakpoint.Xs
const breakpointsConfig: SwiperOptions['breakpoints'] = {
  [defaultBreakpointKey]: {
    slidesPerView: 1.5,
    spaceBetween: 5
  },
  [ViewportBreakpoint.Sm]: {
    slidesPerView: 2.5,
    spaceBetween: 10
  },
  [ViewportBreakpoint.Xl]: {
    slidesPerView: 3.5,
    spaceBetween: 15
  }
}

@Options({
  components: {
    Swiper,
    SwiperSlide,
    Label
  },
  props: {
    label: prop({
      type: String,
      required: false
    }),
    items: prop({
      type: Object as PropType<PropItems>,
      required: true,
      validator: (value: unknown): boolean => Array.isArray(value) || value instanceof Promise
    }),
    modelValue: prop({
      type: Object as PropType<CarouselImageItemValue>,
      required: false
    }),
    preselectFirst: prop({
      type: Boolean,
      default: true
    })
  },
  watch: {
    selectedItem: function (item: CarouselImageItem) {
      this.$emit('update:modelValue', item.value)
    },
    items: function () {
      this.preselectItem()
      this.setItemsCollection()
    },
    sliderHandler: function () {
      this.setNavigationVisibilityState()
      this.registerSliderListeners()
    }
  }
})
export default class CarouselImageSelector extends BaseComponent {
  public readonly items!: PropItems
  public itemsCollection: CarouselImageItem[] | null = null
  public navigationVisibilityState = false
  private readonly modelValue!: CarouselImageItemValue | null
  private readonly preselectFirst!: boolean
  private selectedItem: CarouselImageItem | null = null
  private sliderHandler: Slider | null = null

  public get breakpointsConfig (): SwiperOptions['breakpoints'] {
    return breakpointsConfig
  }

  public get defaultSlidesPerView (): number {
    return (this.breakpointsConfig && this.breakpointsConfig[defaultBreakpointKey]?.slidesPerView as number) || 2.5
  }

  public get defaultSpaceBetweenInPx (): number {
    return (this.breakpointsConfig && this.breakpointsConfig[defaultBreakpointKey]?.spaceBetween) || 10
  }

  public created (): void {
    this.preselectItem()
    this.setItemsCollection()
  }

  public isSelectedItem (item: CarouselImageItem): boolean {
    return this.isSameValue(item.value, this.modelValue)
  }

  public setSliderHandler (slider: Slider): void {
    this.sliderHandler = slider
  }

  public goToNext (): void {
    this.sliderHandler?.slideNext()
  }

  public goToPrev (): void {
    this.sliderHandler?.slidePrev()
  }

  public selectionChanged (item: CarouselImageItem): void {
    this.selectedItem = item
  }

  public registerSliderListeners (): void {
    if (!this.sliderHandler) {
      console.warn('`sliderHandler` is not set - could not register listeners #1663057641829')
      return
    }

    this.sliderHandler.on('breakpoint', () => {
      this.setNavigationVisibilityState()
    })
  }

  public setNavigationVisibilityState (): void {
    setTimeout(() => {
      this.navigationVisibilityState = !!(
        this.itemsCollection &&
        this.itemsCollection.length > 1 &&
        this.sliderHandler &&
        (!this.sliderHandler.isEnd || !this.sliderHandler.isBeginning)
      )
    })
  }

  private preselectItem (): void {
    this.itemsAsPromise().then(items => {
      this.selectedItem = this.modelValue ? (items.find(sliderItem => this.isSameValue(sliderItem.value, this.modelValue)) || null) : this.selectedItem

      if (!this.selectedItem && this.preselectFirst && items[0]) {
        this.selectedItem = items[0]
      }
    })
  }

  private setItemsCollection (): void {
    this.itemsAsPromise().then(items => {
      this.itemsCollection = items
    })
  }

  private isSameValue (valueA: CarouselImageItemValue | null, valueB: CarouselImageItemValue | null): boolean {
    if (valueA === null || valueB === null) {
      return valueA === valueB
    }
    return valueA.uniqueIdentifier === valueB.uniqueIdentifier
  }

  private itemsAsPromise (): Promise<CarouselImageItem[]> {
    if (this.items instanceof Promise) {
      return this.items
    }

    return Promise.resolve(this.items)
  }
}
