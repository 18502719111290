import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-123cee36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = { class: "modal__content" }
const _hoisted_3 = { class: "modal__footer" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueFinalModal = _resolveComponent("VueFinalModal")!

  return (_openBlock(), _createBlock(_component_VueFinalModal, _mergeProps(_ctx.$attrs, {
    classes: "modal-container",
    "content-class": "modal-content"
  }), {
    default: _withCtx(({ params, close }) => [
      _createElementVNode("h1", _hoisted_1, [
        _renderSlot(_ctx.$slots, "title", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", { params: params }, undefined, true)
      ]),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ]),
      _createElementVNode("button", {
        class: "modal__close",
        onClick: close
      }, null, 8, _hoisted_4)
    ]),
    _: 3
  }, 16))
}