import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7df43750"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "number-input" }
const _hoisted_2 = { class: "number-input__inner" }
const _hoisted_3 = { class: "number-input__ui" }
const _hoisted_4 = ["min", "max", "step"]
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("button", {
          class: "number-input__ui__btn number-input__ui__btn--up",
          tabindex: "-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.increment()))
        }),
        _createElementVNode("button", {
          class: "number-input__ui__btn number-input__ui__btn--down",
          tabindex: "-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.decrement()))
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "number",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value) = $event)),
        class: _normalizeClass(["number-input__field", { 'number-input__field--no-default-ui': true }]),
        min: _ctx.min,
        max: _ctx.max,
        step: _ctx.step
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.value]
      ]),
      (_ctx.suffix)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "number-input__suffix",
            textContent: _toDisplayString(_ctx.suffix)
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}